import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Drawer, Tabs, Typography, Spin, Message } from '@nbit/arco'
import CustomModal from '@/features/agent/modal'
import { t } from '@lingui/macro'
import Icon from '@/components/icon'
import { FiatWithdrawLayout } from '@/features/assets/main/withdraw/fiat-withdraw'
import { getTradeChannel, getDefaultCurrency, getWithdrawInfo, getFinanceOrdersManualExist } from '@/apis/assets/fiat'
import { link } from '@/helper/link'
import { CodeGetCodeDetailListData, areaListResp, paymentType } from '@/typings/api/assets/fiat'
import { fetchC2CPaymentReciveList, fetchC2CAreaList } from '@/apis/assets/fiat-payment'
import {
  getC2cTradeRoutePath,
  getThirdPartyPaymentPageRoutePath,
  getMaiWithdrawRoutePath,
  getC2cTopupPageRoutePath,
} from '@/helper/route'
import { useMount, useRequest } from 'ahooks'
import { usePersonalCenterStore } from '@/store/user/personal-center'
import { CurrencyAssetListEnum, TabType } from '@/constants/assets/fiat'
import { getIsDemoUser } from '@/helper/user'
import { useCommonStore } from '@/store/common'
import { CurrencyModeErationEnum, showLocaleInfoContent } from '@/constants/common'
import { getDetermineWhetherMethods } from '@/helper/assets'
import { useC2CAdvertiseStore } from '@/store/c2c/advertise'
import { adCodeDictionaryEnum } from '@/constants/c2c/advertise'
import { FiatDepositLayoutModal } from './fiat-deposit-layout-modal'
import MultiCryptocurrency from './multi-cryptocurrency'
import FillOutForm from './fill-out-form'
import styles from './index.module.css'

interface depositSidebarSettingProps {
  /** 是否显示弹窗 */
  visible: boolean
  /** 字典名字 */
  paymentDealType: CodeGetCodeDetailListData[]
  /** 字典颜色 */
  paymentColor: CodeGetCodeDetailListData[]
  /** 设置显示状态 */
  setVisible: Dispatch<SetStateAction<boolean>>
}

function DepositSidebarSetting({ visible, setVisible, paymentDealType, paymentColor }: depositSidebarSettingProps) {
  const defaultAreaList: areaListResp = {
    countryAbbreviation: '',
    areaRiskWarn: {},
    advertRequire: '',
    payments: [],
    precision: 0,
    currencySymbol: '',
    freezeTime: 0,
    statusCd: '',
    defaultClientTypeCd: '',
    canOutTrade: '',
    sequence: 0,
    legalCurrencyId: '',
    currencyName: '',
    canPublishAdvert: false,
    paymentSupportItems: [],
  } as areaListResp
  const TabPane = Tabs.TabPane
  const [isShowModal, setIsShowModal] = useState(false)
  const [isWithdrawShowModal, setIsWithdrawShowModal] = useState(false)
  const [isFillOutShowModal, setIsFillOutShowModal] = useState(false)
  const {
    fiatCurrencyData: { currencyTypeCd },
    getFiatCurrencyData,
  } = usePersonalCenterStore()
  const [fiatCode, setFiatCode] = useState('')
  const [currentTab, setCurrentTab] = useState(TabType.charge)
  const [c2c, setC2c] = useState(false)
  const [thirdParty, setThirdPart] = useState(false)
  const [ncPlatform, setNcPlatform] = useState(false)
  const [withdraw, setWithdraw] = useState(false)
  const [manualExist, setManualExist] = useState(false)
  const [min, setMin] = useState(0)
  const [max, setMax] = useState(10000000000)
  const [cryptCode, setCryptCode] = useState('')
  const [currencyFiat, setCurrencyFiat] = useState('')
  const [fiatId, setFiatId] = useState('')
  const [paymentsAreaList, setPaymentsAreaList] = useState<areaListResp>(defaultAreaList)
  const { localeInfo } = useCommonStore()

  // 是否是用的字典默认值
  const [isDefault, setIsDefault] = useState(false)

  // 获取支付方式字典值
  const { adCodeDictionary, getAdCodeDictionary } = useC2CAdvertiseStore()
  const getPayTypeColorMap = () => {
    /** 通过字典值组装支付方式和颜色的映射对象 */
    const paymentType = adCodeDictionary[adCodeDictionaryEnum.Payment_Type] || []
    const paymentColor = adCodeDictionary[adCodeDictionaryEnum.Payment_Color] || []
    return Object?.keys(paymentType)?.reduce((prev, curr) => {
      const colorTypeArray = [paymentType[curr]?.codeKey, paymentColor[curr]?.codeKey]
      prev[curr] = colorTypeArray
      return prev
    }, {})
  }

  const paymentTypeLists = (): paymentType[] => {
    const payTypeColorMap = getPayTypeColorMap()
    return Object?.keys(payTypeColorMap)?.reduce((pre, cur, index) => {
      const [label, color] = payTypeColorMap[cur]
      pre.push({
        label,
        color,
        value: cur,
      })
      return pre
    }, [] as paymentType[])
  }
  const [paymentTypeFilterLists, setPaymentTypeFilterLists] = useState<paymentType[]>([])
  const [payments, setPayment] = useState<paymentType[]>(paymentTypeLists() || [])

  const FinanceOrdersManualExist = async () => {
    const res = await getFinanceOrdersManualExist({})
    if (res.isOk && res.data) {
      setManualExist(res?.data?.exist || false)
    }
  }

  // 定义充值和提现的数据
  const depositData = [
    {
      isShow: showLocaleInfoContent(localeInfo?.showDeposit),
      key: TabType.charge,
      title: t`features_home_hero_banner_index_flh6elz9z2`,
      charge: [
        {
          title: t`features_user_components_sidebar_index_ywdlxnfip_`,
          show: withdraw,
          item: [
            {
              icon: 'icon_web_revision_recharge',
              title: t`features_user_components_sidebar_index_qykymnpzp8`,
              description: t`features_user_components_sidebar_index_sedc67myrp`,
              onclick: () => {
                link(getC2cTopupPageRoutePath())
                setVisible(false)
              },
            },
          ],
        },
      ],
      withdraw: [
        {
          title: t`features_user_components_sidebar_index_yppi2i8e94`,
          items: [
            {
              type: CurrencyAssetListEnum.c2cBuyCoins,
              icon: 'icon_web_revision_c2c',
              title: t`features_user_components_sidebar_index_f8o8nhdjgg`,
              description: t`features_user_components_sidebar_index_tbtfq6g3af`,
              onclick: () => {
                link(getC2cTradeRoutePath())
                setVisible(false)
              },
              show: c2c,
            },
            {
              type: CurrencyAssetListEnum.fiatCurrency,
              icon: 'icon_web_revision_dsf',
              title: t`modules_trade_third_party_payment_index_page_server_bkloey7_dq`,
              description: t`features_user_components_sidebar_index_lchouk70wi`,
              onclick: () => {
                link(getThirdPartyPaymentPageRoutePath())
                setVisible(false)
              },
              show: thirdParty,
            },
            {
              type: CurrencyAssetListEnum.fillOutDeposit,
              icon: 'icon_web_revision_chongzhi',
              title: t({
                id: 'features_user_components_sidebar_index_dmbptkonem',
                values: { 0: fiatCode },
              }),
              description: t`features_user_components_sidebar_index_kb_ytyt9tr`,
              onclick: () => {
                setIsShowModal(true)
              },
              show: ncPlatform,
            },
            {
              type: CurrencyAssetListEnum.thirdPartyTransactions,
              icon: 'icon_task_contract_deposit',
              title: t`features_user_components_sidebar_index_mvi6vsq9ko`,
              description: `${t`features_vip_vip_center_modals_index_w_upjufnkf`}。`,
              onclick: () => {
                setIsFillOutShowModal(true)
              },
              show: manualExist,
            },
          ],
        },
      ],
    },
    {
      isShow: showLocaleInfoContent(localeInfo?.showWithdrawal),
      key: TabType.withdraw,
      title: t`features_user_components_sidebar_index_etltjevzl9`,
      charge: [
        {
          title: t`features_user_components_sidebar_index_9sg0eelinu`,
          show: withdraw,
          item: [
            {
              icon: 'icon_web_revision_withdrawal_bit',
              title: t`features_user_components_sidebar_index_ikfca4dprl`,
              description: t`features_user_components_sidebar_index_uzrukawc4m`,
              onclick: () => {
                link(getMaiWithdrawRoutePath())
                setVisible(false)
              },
            },
          ],
        },
      ],
      withdraw: [
        {
          title: t`features_user_components_sidebar_index_8mg3ehxf3h`,
          items: [
            {
              icon: 'icon_web_revision_c2c',
              title: t`features_user_components_sidebar_index_eyuc3mfcnz`,
              description: t`features_user_components_sidebar_index_tbtfq6g3af`,
              onclick: () => {
                link(getC2cTradeRoutePath())
                setVisible(false)
              },
              show: c2c,
            },
            {
              icon: 'icon_web_revision_dsf',
              title: t`modules_trade_third_party_payment_index_page_server_bkloey7_dq`,
              description: t`features_user_components_sidebar_index_lchouk70wi`,
              onclick: () => {
                link(getThirdPartyPaymentPageRoutePath())
                setVisible(false)
              },
              show: thirdParty,
            },
            {
              type: CurrencyAssetListEnum.fiatCurrencyWithdrawal,
              icon: 'icon_web_revision_chongzhi',
              title: t({
                id: 'features_user_components_sidebar_index_y4zixxmsmv',
                values: { 0: fiatCode },
              }),
              description: t`features_user_components_sidebar_index_biihlzpqll`,
              onclick: () => {
                setIsWithdrawShowModal(true)
              },
              show: ncPlatform,
            },
          ],
        },
      ],
    },
  ].filter(res => res?.isShow)

  const defaultCurrency = async () => {
    const res = await getDefaultCurrency({ fiatCode: currencyTypeCd })
    setFiatCode(res?.data)
  }

  useEffect(() => {
    getFiatCurrencyData()
    localeInfo?.currencyMode !== CurrencyModeErationEnum.multiCurrency && defaultCurrency()
    FinanceOrdersManualExist()
  }, [])

  const c2cPaymentReciveListOnLoad = async (customLegalCurrencyId?) => {
    const { isOk: isAreaOk, data: areaList } = await fetchC2CAreaList({ returnAll: true })

    const initialLegalCurrencyId = areaList?.find(val => val?.currencyName === fiatCode)?.legalCurrencyId

    const legalCurrencyId = customLegalCurrencyId || initialLegalCurrencyId

    if (legalCurrencyId) {
      const { isOk: isPaymentOk, data: paymentList } = await fetchC2CPaymentReciveList(
        legalCurrencyId ? { legalCurrencyId } : {}
      )
      const recivePaymentList = paymentList?.recivePaymentList

      const updatedRecivePaymentList = recivePaymentList?.map(item => {
        const paymentDealTypeInfo = paymentDealType.find(dealType => dealType.codeVal === item.paymentTypeCd)
        const paymentColorInfo = paymentColor.find(color => color.codeVal === item.paymentTypeCd)
        const label = paymentDealTypeInfo ? paymentDealTypeInfo.codeKey : item.paymentTypeCd
        const color = paymentColorInfo ? paymentColorInfo.codeKey : ''

        return {
          label,
          color,
          value: item?.paymentTypeCd,
          id: item?.id,
        }
      })
      if (updatedRecivePaymentList && updatedRecivePaymentList.length !== 0) {
        setPaymentTypeFilterLists(updatedRecivePaymentList)
        setIsDefault(false)
      } else {
        setPaymentTypeFilterLists(paymentTypeLists())
        setIsDefault(true)
      }
      const paymentsAreaList = areaList?.find(val => val.legalCurrencyId === legalCurrencyId)
      if (paymentsAreaList) {
        setPaymentsAreaList(paymentsAreaList)
      }
      const paymentsData = paymentsAreaList?.payments
      const updatedPaymentsList = paymentsData?.map(item => {
        const paymentDealTypeInfo = paymentDealType.find(dealType => dealType.codeVal === item)
        const paymentColorInfo = paymentColor.find(color => color.codeVal === item)
        let accountInfo
        if (recivePaymentList && recivePaymentList?.length > 0) {
          accountInfo = recivePaymentList?.filter(account => account.paymentTypeCd === item)
        }
        let showRight
        if (accountInfo && accountInfo.length > 1) {
          showRight = true
        } else {
          showRight = false
        }
        return {
          label: paymentDealTypeInfo ? paymentDealTypeInfo.codeKey : item,
          color: paymentColorInfo ? paymentColorInfo.codeKey : '',
          value: item,
          account: accountInfo && accountInfo[0]?.account ? accountInfo[0]?.account : '',
          qrCodeAddr: accountInfo && accountInfo[0]?.qrCodeAddr ? accountInfo[0]?.qrCodeAddr : '',
          IsShowRight: showRight,
          id: accountInfo && accountInfo[0]?.id ? accountInfo[0]?.id : '',
          accountInfo,
          currencyName: paymentsAreaList?.currencyName,
        }
      })
      if (updatedPaymentsList) {
        updatedPaymentsList?.forEach((res, index) => {
          res?.accountInfo?.forEach(val => {
            val.newIndex = index
          })
        })
        setPayment(updatedPaymentsList)
      }
    }
  }

  const changeAccount = (val, paymentMeth) => {
    const newData = payments.map(item => {
      if (item.value === paymentMeth) {
        return { ...item, id: val }
      }
      return item
    })
    setPayment(newData)
  }

  const getShowTradeChannel = async type => {
    setC2c(false)
    setThirdPart(false)
    setNcPlatform(false)
    setWithdraw(false)
    const res = await getTradeChannel(type)
    if (!res.data) return
    const { c2c, thirdParty, ncPlatform, withdraw } = res.data
    setC2c(c2c)
    setThirdPart(thirdParty)
    setNcPlatform(ncPlatform)
    setWithdraw(withdraw)
  }

  const { loading, run } = useRequest(getShowTradeChannel, { manual: true })

  useEffect(() => {
    switch (currentTab) {
      case TabType.charge:
        // 充值
        run({ type: TabType.charge })
        break
      case TabType.withdraw:
        // 提现
        fetchData(fiatCode)
        run({ type: TabType.withdraw })
        break
      default:
        break
    }
  }, [currentTab])

  useMount(() => {
    getAdCodeDictionary()
  })

  const fetchData = async code => {
    if (localeInfo?.currencyMode === CurrencyModeErationEnum.multiCurrency) return
    const res = await getWithdrawInfo({ fiatCode: code })
    if (!res.data) return
    const { min, max, cryptCode, fiatCode, fiatId } = res.data
    setFiatId(fiatId || '')
    setCurrencyFiat(fiatCode || '')
    setMin(min || 0)
    setMax(max || 10000000000)
    setCryptCode(cryptCode || '')
  }

  const changeLegalCurrencyId = () => {
    c2cPaymentReciveListOnLoad(fiatId)
  }

  const changeCharge = val => {
    fetchData(val)
  }

  const onClickCell = i => {
    if (!i.onclick) return
    if (getIsDemoUser())
      return Message.error(t`features_assets_common_transfer_common_transfer_button_index_uvmnnkvi4e`)
    i?.onclick()
  }

  return (
    <div>
      <Drawer
        width={400}
        title={getDetermineWhetherMethods(true)}
        visible={visible}
        footer={null}
        className={`deposit-setting-drawer-style ${styles.scoped}`}
        onOk={() => {
          setVisible(false)
        }}
        onCancel={() => {
          setVisible(false)
        }}
      >
        <Spin loading={loading} className={'fiat-loading'}>
          <Tabs
            defaultActiveTab={currentTab}
            onClickTab={key => {
              setCurrentTab(key as TabType)
            }}
          >
            {depositData.map((tab, index) => (
              <TabPane key={tab.key} title={tab.title}>
                <Typography.Paragraph>
                  {tab?.charge?.map(
                    (item, itemIndex) =>
                      item.show && (
                        <div key={String(itemIndex)} className="mb-6">
                          <p className="deposit-title">{item.title}</p>
                          {item?.item?.map((i, index) => (
                            <div className="deposit-box" key={index} onClick={() => onClickCell(i)}>
                              <Icon name={i.icon} className="icon-assets" />
                              <div className="deposit-box-middle">
                                <p className="title">{i.title}</p>
                                <p className="deposit-context">{i.description}</p>
                              </div>
                              <Icon name="transaction_arrow" className={'arrow-icon'} />
                            </div>
                          ))}
                        </div>
                      )
                  )}
                  {/* {tab?.withdraw?.map((item, itemIndex) => (
                    <div key={String(itemIndex)} className="mb-6">
                      {(c2c || thirdParty || ncPlatform) && <p className="deposit-title">{item.title}</p>}
                      {item?.items?.map(
                        (i, index) =>
                          i.show && (
                            <div className="deposit-box deposit-pt" key={index} onClick={() => onClickCell(i)}>
                              <Icon name={i.icon} className="icon-assets" />
                              <div className="deposit-box-middle">
                                <p className="title">{i.title}</p>
                                <p className="deposit-context">{i.description}</p>
                              </div>
                              <Icon name="transaction_arrow" className={'arrow-icon'} />
                            </div>
                          )
                      )}
                    </div>
                  ))} */}
                  <MultiCryptocurrency
                    paymentDealType={paymentDealType}
                    currentTab={currentTab}
                    onClickCell={onClickCell}
                    ncPlatform={ncPlatform}
                    thirdParty={thirdParty}
                    c2c={c2c}
                    tab={tab}
                  />
                </Typography.Paragraph>
              </TabPane>
            ))}
          </Tabs>
        </Spin>
      </Drawer>

      {isShowModal && (
        <FiatDepositLayoutModal
          isShowModal={isShowModal}
          fiatCode={fiatCode}
          paymentDealType={paymentDealType}
          setClose={() => {
            setIsShowModal(false)
          }}
        />
      )}

      {isWithdrawShowModal && (
        <CustomModal style={{ width: 600 }} visible={isWithdrawShowModal}>
          <FiatWithdrawLayout
            cryptCode={cryptCode}
            paymentTypeFilterLists={paymentTypeFilterLists}
            payments={payments}
            firstFiatCode={fiatCode}
            currencyFiat={currencyFiat}
            isDefault={isDefault}
            max={max.toString()}
            min={min.toString()}
            // paymentsAreaList={paymentsAreaList}
            onclick={() => {
              setIsWithdrawShowModal(false)
              setVisible(false)
            }}
            changeLegalCurrencyId={changeLegalCurrencyId}
            changeCharge={changeCharge}
            changeAccount={changeAccount}
          />
        </CustomModal>
      )}

      {isFillOutShowModal && (
        <FillOutForm fiat={fiatCode} setIsFillOutShowModal={setIsFillOutShowModal} visible={isFillOutShowModal} />
      )}
    </div>
  )
}

export { DepositSidebarSetting }
