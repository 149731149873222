/** 填单存款组建 */
import cn from 'classnames'
import CustomModal from '@/features/agent/modal'
import Icon from '@/components/icon'
import { useEffect, useState } from 'react'
import { Button, Trigger, Message, Skeleton } from '@nbit/arco'
import { t } from '@lingui/macro'
import { useCommonStore } from '@/store/common'
import LazyImage from '@/components/lazy-image'
import { oss_svg_image_domain_address } from '@/constants/oss'
import { usePersonalCenterStore } from '@/store/user/personal-center'
import { useAssetsFiatStore } from '@/store/assets/fiat'
import {
  PostFinanceOrdersInfoV2,
  getFinanceOrdersInfo,
  getFinanceOrdersInfoV2,
  postFinanceOrdersAdd,
} from '@/apis/assets/fiat'
import { fiatCurrencyType, AllChargeListResp, payInfoType } from '@/typings/api/assets/fiat'
import { FiatAdvertise } from '@/features/assets/main/deposit/fiat-deposit/advertise'
import { useCopyToClipboard } from 'react-use'
import { useRequest } from 'ahooks'
import { useAssetsStore } from '@/store/assets'
import { getIsSingleFaitMode } from '@/helper/assets/fiat'
import { CurrencyModeErationEnum } from '@/constants/common'
import TradeInputNumber from '@/components/trade-input-number'
import MultiTradingArea from './components/multi-trading-area'
import style from './index.module.css'

const offset = 2
type FillOutFormType = {
  /** 弹窗参数 */
  visible: boolean
  /** 弹窗方法 */
  setIsFillOutShowModal: (e: boolean) => void
  /** 法币参数 */
  fiat?: string
}
function FillOutForm(props: FillOutFormType) {
  const { localeInfo } = useCommonStore()
  const { faitEnums } = useAssetsFiatStore() || {}
  const paymentMethod = faitEnums.paymentDicCodeEnum.enums
  const { visible, setIsFillOutShowModal, fiat } = props
  const [state, copyToClipboard] = useCopyToClipboard()
  const { fiatCurrencyData } = usePersonalCenterStore()
  const [listPix, setListPix] = useState({
    list: [] as payInfoType[],
    pixObj: [],
    itemObj: {} as payInfoType,
  })
  const [ordersInfo, setOrdersInfo] = useState({
    levels: [] as AllChargeListResp[],
    fiatCurrency: [] as fiatCurrencyType[],
    payInfo: [] as payInfoType[],
  })
  const [amountForm, setAmountForm] = useState({
    amount: '' as string | number,
    activeLever: '' as string,
    popupVisible: false as boolean,
    listBid: [] as Array<number>,
  })
  const { merchantInfo } = useAssetsStore()

  /** 查询法币信息 */
  const { run: FinanceOrdersInfo, loading: financeLoading } = useRequest(
    async () => {
      const res = await getFinanceOrdersInfo({ fiatCode: fiatCurrencyData.currencyEnName || '' })
      if (res.isOk && res.data) {
        setOrdersInfo({
          levels: res?.data?.levels || [],
          fiatCurrency: res?.data?.fiatCurrency || [],
          payInfo: res?.data?.payInfo || [],
        })

        setListPix(() => {
          const val = { ...listPix }
          res?.data?.payInfo &&
            res?.data?.payInfo.forEach(resq => {
              val.list.push({ ...resq })
            })
          val.list.map(keyVal => {
            const paymentDealTypeInfo = paymentMethod.find(dealType => dealType?.codeVal === keyVal?.channelCode)
            keyVal.codeName = paymentDealTypeInfo?.codeKey
            return keyVal
          })
          val.itemObj = val.list[0]
          return val
        })
      }
    },
    { manual: true }
  )

  /** 清楚方法 */
  const closeFn = () => {
    setAmountForm({ amount: '', activeLever: '', popupVisible: false, listBid: [] })
    setIsFillOutShowModal(false)
  }

  /** 提交事件 */
  const { run: getDeposit, loading: DepositLoading } = useRequest(
    async () => {
      const res =
        localeInfo?.currencyMode === CurrencyModeErationEnum.multiCurrency
          ? await PostFinanceOrdersInfoV2({
              id: listPix.itemObj?.id,
              amount: amountForm?.amount || '',
            })
          : await postFinanceOrdersAdd({
              fiatCode: fiat || fiatCurrencyData?.currencyEnName || '',
              amount: amountForm?.amount || '',
              levelId: amountForm?.activeLever,
            })
      if (res.isOk && res.data) {
        if (res?.data?.isSuccess) {
          closeFn()
          Message.success(t`features_user_components_sidebar_fill_out_form_index_ts916sq7d_`)
        }
      }
    },
    { manual: true }
  )

  /** 选中点击事件 */
  const onTrigger = item => {
    setListPix({ ...listPix, itemObj: item })
    setAmountForm({ ...amountForm, popupVisible: false, amount: '' })
    setOrdersInfo({ ...ordersInfo, levels: item?.levels || [] })
  }

  /** 复制 */
  const onCopy = e => {
    if (!e) {
      return
    }
    copyToClipboard(e)
    state.error
      ? Message.error(t`assets.financial-record.copyFailure`)
      : Message.success(t`assets.financial-record.copySuccess`)
  }

  /** 查询填单存款信息 */
  const queryFinanceOrdersInfoV2 = async (fiatCode, areaCurrency) => {
    const res = await getFinanceOrdersInfoV2({ fiatCode: fiatCode || '', areaCurrency: areaCurrency || '' })
    if (res.isOk && res?.data) {
      setListPix(() => {
        const val = { ...listPix }
        val.list = res?.data?.payInfo || []
        val.itemObj = val.list[0]
        return val
      })

      setOrdersInfo(() => {
        const val = { ...ordersInfo }
        res?.data?.payInfo?.forEach(row => {
          val.levels = row?.levels || []
        })
        return val
      })
    }
  }

  useEffect(() => {
    if (visible && localeInfo?.currencyMode !== CurrencyModeErationEnum.multiCurrency) {
      FinanceOrdersInfo()
    }
  }, [visible])

  return (
    <CustomModal className={style['scoped-fill']} visible={visible}>
      <div className="herder-box">
        <div className="herder-text">{t`features_user_components_sidebar_index_mvi6vsq9ko`}</div>
        <div>
          <Icon
            onClick={() => {
              setIsFillOutShowModal(false)
            }}
            name="close"
          />
        </div>
      </div>
      {localeInfo?.currencyMode === CurrencyModeErationEnum.multiCurrency && (
        <MultiTradingArea
          queryFinanceOrdersInfoV2={(fiatCoin, event) => {
            setAmountForm({
              ...amountForm,
              amount: '',
              activeLever: '',
            })
            queryFinanceOrdersInfoV2(fiatCoin, event)
          }}
        />
      )}

      <Skeleton loading={false} animation>
        <div className="fill-trigger">
          <Trigger
            onClickOutside={() => {
              setAmountForm({ ...amountForm, popupVisible: false })
            }}
            popupVisible={amountForm.popupVisible}
            popup={() => (
              <div className={style['trigger-layout-menu-wrap']}>
                {listPix?.list &&
                  listPix?.list?.map((res, index) => {
                    return (
                      <div key={index} className="trigger-flex" onClick={() => onTrigger(res)}>
                        <div>
                          <LazyImage src={res?.logo || ''} />
                        </div>
                        <div>{res?.codeName || ''}</div>
                      </div>
                    )
                  })}
              </div>
            )}
            position="bottom"
            trigger={'click'}
          >
            <div
              onClick={() => {
                setAmountForm({ ...amountForm, popupVisible: !amountForm.popupVisible })
              }}
              className="fill-img-box"
            >
              <div>
                <LazyImage src={listPix.itemObj?.logo || ''} />
              </div>
              <div className="fill-img-text">{listPix.itemObj?.codeName || ''}</div>
              <div>
                <Icon name={amountForm.popupVisible ? 'icon_agent_center_away' : 'icon_agent_center_drop'} />
              </div>
            </div>
          </Trigger>
        </div>
        <div className="fill-alipay">
          <div className="fill-alipay-account">
            {t`features_c2c_advertise_post_advertise_index_jfc9ao0xd-erkuglbnl67`}
            {t`features_assets_main_withdraw_fiat_withdraw_index_itckuzbtec`}
          </div>
          <div className="fill-alipay-flex">
            <div className="fill-flex-text">
              {t`features_user_components_sidebar_filloutform_index_p_3fxtnuwh`}
              {t`features_assets_main_withdraw_fiat_withdraw_index_itckuzbtec`}
            </div>
            <div className="fill-flex-context">{listPix.itemObj?.receiveName || ''}</div>
            <div
              className="fill-flex-copy"
              onClick={() => {
                onCopy(listPix.itemObj?.receiveName || '')
              }}
            >
              {t`features/user/personal-center/settings/api/index-0`}
            </div>
          </div>
          <div className="fill-alipay-flex">
            <div className="fill-flex-text">
              {t`features_c2c_center_coin_switch_index_3rawstucyu0jlw1lxln_i`}
              {t`features_assets_main_withdraw_fiat_withdraw_index_itckuzbtec`}
            </div>
            <div className="fill-flex-context">{listPix.itemObj?.receiveAccount || ''}</div>
            <div
              className="fill-flex-copy"
              onClick={() => {
                onCopy(listPix.itemObj?.receiveAccount || '')
              }}
            >
              {t`features/user/personal-center/settings/api/index-0`}
            </div>
          </div>
          <div>
            <div className="fill-text">
              {t`features_user_components_sidebar_filloutform_index_rusugssscp`}
              {t`features_assets_main_withdraw_fiat_withdraw_index_itckuzbtec`}
            </div>
            {listPix?.itemObj?.imgUrl && (
              <div className="fill-qr">
                <div className="fill-qr-box">
                  <img src={listPix?.itemObj?.imgUrl || ''} alt="" />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="fill-input">
          <div className="fill-input-text">{t`features_assets_main_deposit_fiat_deposit_index_7cdgpvv_vl`}:</div>
          <TradeInputNumber
            className="fill-input-placeholder"
            precision={getIsSingleFaitMode() ? merchantInfo?.coinPrecision : offset}
            min={0}
            max={9999999999}
            placeholder={t`user.common.enter`}
            onChange={val => {
              const matchedItem = ordersInfo?.levels.find(item => item.amount === val)
              setAmountForm({ ...amountForm, amount: val || '', activeLever: String(matchedItem?.amount || '') })
            }}
            value={amountForm?.amount}
          />
        </div>
        <div className="all-box">
          {ordersInfo?.levels &&
            ordersInfo?.levels?.map((i, index) => (
              <div
                key={index}
                className={cn('small-box', {
                  active: i?.id === amountForm?.activeLever || i.amount === amountForm?.amount,
                })}
                onClick={() => {
                  if (i?.id === amountForm?.activeLever) {
                    setAmountForm({ ...amountForm, amount: '', activeLever: '' })
                  } else {
                    setAmountForm({ ...amountForm, amount: String(i?.amount) || '', activeLever: i?.id || '' })
                  }
                }}
              >
                <div className="all-flex">
                  <div>
                    {localeInfo?.currencyMode !== CurrencyModeErationEnum.multiCurrency && i?.logo && (
                      <LazyImage src={i?.logo || ''} className="fiat-logo" />
                    )}
                  </div>
                  <div>
                    <p className="coin">{`${i?.symbol || ''} ${i?.amount || ''}`}</p>
                  </div>
                </div>

                {i?.bonus && Number(i?.bonus) > 0 ? (
                  <p className="bonus">
                    {' '}
                    + {i?.bonus || '--'}% {t`features_assets_main_deposit_fiat_deposit_index_c5fkhy9xnz`}
                  </p>
                ) : null}

                {i?.isHot && (
                  <LazyImage
                    src={`${oss_svg_image_domain_address}icon_fiat_hot_tag${
                      String(i.amount) === amountForm?.amount ? '_active' : ''
                    }.png`}
                    className="hot-wrap"
                  />
                )}
              </div>
            ))}
        </div>
        <Button
          type="primary"
          className={'deposit'}
          onClick={getDeposit}
          disabled={!amountForm?.amount || Number(amountForm?.amount) < 0}
          loading={DepositLoading}
        >
          {t`features_assets_main_deposit_fiat_deposit_index_61yycb8l5o`}
        </Button>

        {/* TODO: 910000 商户下展示固定的图片 (240057 兼容测试环境) */}
        <FiatAdvertise onlyImg={false} />
      </Skeleton>
    </CustomModal>
  )
}

export default FillOutForm
