import request, { MarkcoinRequest } from '@/plugins/request'

/**
 * c2c 中心 - 获取所有现货账户币种信息
 * https://yapi.nbttfc365.com/project/44/interface/api/398
 */
export const fetchAssetCoinBalance: MarkcoinRequest = options => {
  return request({
    path: `/v1/asset/coin/balance`,
    method: 'GET',
    params: {
      pageNum: options.pageNum,
      pageSize: options.pageSize,
      isGt: options.isGt,
      scenes: options.scenes,
    },
  })
}

/**
 * c2c 中心 - 资金划转
 * https://yapi.nbttfc365.com/project/73/interface/api/4583
 */
export const fetchC2CBalanceTransfer: MarkcoinRequest = options => {
  return request({
    path: `/v1/c2c/balance/transfer`,
    method: 'POST',
    data: {
      coinId: options.coinId, // 币种 id
      typeCd: options.typeCd, // 1.划转至交易账户 2.划转至 C2C 账户
      amount: options.amount, // 划转金额
    },
  })
}

/**
 * c2c 中心 - 获取所有币种信息
 * https://yapi.nbttfc365.com/project/73/interface/api/5192
 */
export const fetchC2CCoinAll: MarkcoinRequest = () => {
  return request({
    path: `/v1/c2c/coin/all`,
    method: 'GET',
  })
}
